import React, { useState } from "react";
import { FaPlus } from "react-icons/fa6";
import { FaqDatas } from "./data";
import { FaMinus } from "react-icons/fa6";

const FAQ = () => {
  const [FaqData, setFaqData] = useState(FaqDatas);
  const [open, setOpen] = useState(false);

  const handleShowList = (id: number) => {
    const updatedData = FaqData.map((item) => {
      if (item.id === id) {
        return { ...item, show: !item.show };
      }
      return item;
    });
    setFaqData(updatedData);
  };

  return (
    <div id="faq" className="w-full lg:h-[700px] h-auto bg-[#F4F4F4] mt-28">
      <section className="lg:flex lg:p-24 p-3">
        <h1 className="lg:w-[30%] lg:h-[100px] text-[48px] font-[500]">FAQs</h1>
        <div className="lg:w-[70%] w-full h-[500px] overflow-y-scroll p-5">
          {FaqData.map((item, key) => (
            <div key={key} className="relative lg:mb-2 mb-5">
              <span className="lg:w-full lg:h-[50px] h-auto flex items-center lg:text-2xl text-lg justify-between p-2">
                <h1>{item.title}</h1>
                {item.show ? (
                  <FaMinus
                    className=" cursor-pointer hover:text-teal-500 duration-300"
                    onClick={() => handleShowList(item.id)}
                  />
                ) : (
                  <FaPlus
                    className=" cursor-pointer hover:text-teal-500 duration-300"
                    onClick={() => handleShowList(item.id)}
                  />
                )}
              </span>

              <div
                className={`w-full  h-auto ${item.show ? "mb-5" : "mb-0 p-1"} `}
              >
                <span
                  className={`${
                    item.show
                      ? "visible h-[550px] lg:text-[18px] text-[16px] mb-5"
                      : " hidden"
                  }`}
                >
                  {item.desc}
                </span>
              </div>
              <hr className="border-[1px] w-full" />
            </div>
          ))}
        </div>
      </section>
    </div>
  );
};

export default FAQ;
