import useTheme from "@mui/material/styles/useTheme";
import useMediaQuery from "@mui/material/useMediaQuery";

export interface IMediaQuery {
    query: "up" | "down" | "between" | "only";
    key: "xs" | "sm" | "md" | "lg" | "xl";
    start?: "xs" | "sm" | "md" | "lg" | "xl";
    end?: "xs" | "sm" | "md" | "lg" | "xl";
}
  
export default function useResponsive(props: IMediaQuery): boolean {
  const { query, key, start, end } = props;
  const theme = useTheme();

  const mediaUp = useMediaQuery(theme.breakpoints.up(key));
  const mediaDown = useMediaQuery(theme.breakpoints.down(key));
  const mediaBetween = useMediaQuery(theme.breakpoints.between(start!, end!));
  const mediaOnly = useMediaQuery(theme.breakpoints.only(key));

  if (query === "up") return mediaUp;
  if (query === "down") return mediaDown;
  if (query === "between") return mediaBetween;
  if (query === "only") return mediaOnly;

  return false;
}
