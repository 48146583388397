import React from "react";
import { IMediaQuery } from "./hook";
import useResponsive from "./hook";
import Dialog, { DialogProps } from "@mui/material/Dialog/Dialog";
import DialogTitle from "@mui/material/DialogTitle/DialogTitle";
import Grid from "@mui/material/Grid";
import IconButton from "@mui/material/IconButton/IconButton";
import Typography from "@mui/material/Typography/Typography";
import DialogContent from "@mui/material/DialogContent/DialogContent";
import DialogActions from "@mui/material/DialogActions/DialogActions";
import { ArrowBack, CloseOutlined } from "./svg";

interface Props extends DialogProps {
  open: boolean;
  onClose: () => void;
  titleComponent?: React.ReactNode;
  title?: string;
  children: React.ReactNode;
  actions?: React.ReactNode;
  hideTitle?: boolean;
  canScroll?: boolean;
  hideBackButton?: boolean;
}

const DialogModal: React.FC<Props> = ({
  open,
  onClose,
  title,
  hideTitle = false,
  children,
  canScroll,
  actions,
  hideBackButton,
  titleComponent,
  ...rest
}) => {
  const params: IMediaQuery = { query: "up", key: "sm" };
  const smUp = useResponsive(params);

  return (
    <Dialog
      open={open}
      onClose={onClose}
      fullWidth={true}
      fullScreen={
        rest.fullScreen || rest.fullScreen === false ? rest.fullScreen : !smUp
      }
      maxWidth={rest.maxWidth || "md"}
      sx={{
        my: 5,
        mx: 2,
        "& .MuiDialog-paper": {
          borderRadius: 3,
          minHeight: "100%",
          overflow: canScroll ? "auto" : "hidden",
        },
      }}
      {...rest}
    >
      {!hideTitle && (
        <DialogTitle
          sx={{
            backgroundColor: "#F0F0F0",
            padding: 1,
            height: 60,
            borderBottom: "1px solid #E0E0E0",
            "& .MuiTypography-h6": {
              color: "#333333",
            },
          }}
        >
          <Grid
            container
            justifyContent="space-between"
            alignItems="center"
            p={1}
            px={2}
          >
            {!smUp && !hideBackButton && (
              <IconButton onClick={onClose}>
                <ArrowBack fontSize="small" />
              </IconButton>
            )}
            {title && (
              <Typography variant="body1" fontWeight={600}>
                {title}
              </Typography>
            )}
            {titleComponent && titleComponent}
            {smUp && (
              <IconButton onClick={onClose}>
                <CloseOutlined fontSize="small" />
              </IconButton>
            )}
          </Grid>
        </DialogTitle>
      )}
      <DialogContent
        sx={{
          padding: 2,
          overflow: canScroll ? "auto" : "hidden",
        }}
      >
        {children}
      </DialogContent>
      {actions && (
        <DialogActions
          sx={{
            borderTop: "1px solid #E0E0E0",
            maxHeight: 60,
          }}
        >
          {actions}
        </DialogActions>
      )}
    </Dialog>
  );
};

export default DialogModal;
