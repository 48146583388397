import React from "react";
import Navbar from "../../components/navbar/navbar";
import "../aboutUs/abt.css";
import Footer from "../footer/footer";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as Yup from "yup";
import axios from "axios";

const Partner = () => {
  let validations: any = {
    first_name: Yup.string().required("FirstName is required").min(2),
    last_name: Yup.string().required("Lastname is required").min(2),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "The email should be like this 'test@example.com'"
      ),
    phone: Yup.number().required("Phone is required").min(5),
  };

  const validation = (validations: any) => {
    if (validations) return Yup.object().shape(validations);
    else return Yup.object().shape({});
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validation(validations) as any) });

  const onSubmit = (data: any) => {
    axios
      .post("/api/partner/", {
        ...data,
      })
      .then((res) => {
        alert("Message received")
        window.location.reload()
      })
      .catch((err) => console.log(err));
  };


  return (
    <div>
      <Navbar />
      <div className=" pt-24 flex flex-col items-center relative">
        <div className="absolute top-0 left-0 w-full h-[400px] cl z-[-10]"></div>
        <h1 className="text-[46px] mb-24 pt-24">
          <span className="font-bold">Partner </span>with Us.
        </h1>
        <form onSubmit={handleSubmit(onSubmit)} className="lg:flex">
          <section className="lg:w-[700px] h-auto lg:border-[1px] border-black rounded-xl p-10">
            <div>
              <section className="flex items-center w-full gap-5 mb-5">
                <div className="w-full">
                  <h1 className="mb-3 font-[600]">First Name*</h1>
                  <input className="w-full h-[50px] rounded-xl border-[1px] border-black"
                    {...register("first_name")}

                  />
                </div>

                <div className="w-full">
                  <h1 className="mb-3 font-[600]">Last Name*</h1>
                  <input {...register("last_name")}
                    className="w-full h-[50px] rounded-xl border-[1px] border-black" />
                </div>
              </section>

              <section className="flex items-center w-full gap-5 mb-5">
                <div className="w-full">
                  <h1 className="mb-3 font-[600]">Work Email*</h1>
                  <input {...register("email")} className="w-full h-[50px] rounded-xl border-[1px] border-black" />
                </div>

                <div className="w-full">
                  <h1 className="mb-3 font-[600]">Phone Number*</h1>
                  <input {...register("phone")} className="w-full h-[50px] rounded-xl border-[1px] border-black" />
                </div>
              </section>

              <section className="flex items-center w-full gap-5 mb-5">
                <div className="w-full">
                  <h1 className="mb-3 font-[600]">Company Name*</h1>
                  <input {...register("company_name")} className="w-full h-[50px] rounded-xl border-[1px] border-black" />
                </div>

                <div className="w-full">
                  <h1 className="mb-3 font-[600]">Job Title*</h1>
                  <input {...register("job_title")} className="w-full h-[50px] rounded-xl border-[1px] border-black" />
                </div>
              </section>

              <section className="flex items-center w-full gap-5 mb-5">
                <div className="w-full">
                  <h1 className="mb-3 font-[600]">
                    What country is your company located in?*
                  </h1>
                  <input {...register("company_location")} className="w-full h-[50px] rounded-xl border-[1px] border-black" />
                </div>
              </section>
              <section className="flex items-center w-full gap-5 mb-5">
                <div className="w-full">
                  <h1 className="mb-3 font-[600]">Website URL*</h1>
                  <input
                    {...register("website_url")}
                    placeholder="https://www.youtube.com/"
                    className="w-full h-[50px] rounded-xl border-[1px] border-black p-2"
                  />
                </div>
              </section>
              <section className="flex items-center w-full gap-5 mb-5">
                <div className="w-full">
                  <h1 className="mb-3 font-[600]">
                    Please describe your company.*
                  </h1>
                  <textarea {...register("description")} required className="w-full h-[100px] rounded-xl border-[1px] border-black" />
                </div>
              </section>
              <section className="flex items-center w-full gap-5 mb-5">
                <div className="w-full">
                  <h1 className="mb-3 font-[600]">
                    How would you like to partner with Us?*
                  </h1>
                  <textarea {...register("how_description")} className="w-full h-[100px] rounded-xl border-[1px] border-black" />
                </div>
              </section>
              <p className="mb-5">
                Your privacy is important to us Privacy Policy.
              </p>
              <div className="w-full flex justify-center">
                <button className="w-[100px] h-[40px] rounded-md bg-teal-500 text-white">
                  sign up
                </button>
              </div>
            </div>
          </section>
          <div className="lg:w-[400px] p-10">
            <h1 className="text-[22px] font-bold mb-5">
              Are you an EHR vendor and interested in partnering with Us?
            </h1>
            <p className="text-sm mb-5">
              As a medical AI scribe, MyPractice offers a unique and immersive
              experience for both doctors and patients, making the documentation
              of clinical notes faster and more efficient than ever before. By
              integrating with MyPractice, EHR vendors can offer their customers a
              cutting-edge solution that streamlines the documentation process
              and improves overall workflow.
            </p>
            <p className="text-sm">
              Contact us today to learn more about how we can work together to
              enhance the healthcare experience for providers and patients
              alike. Don’t miss out on the opportunity to be a part of the
              future of healthcare documentation.
            </p>
          </div>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Partner;
