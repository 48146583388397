import React from "react";
import "./App.css";
import Intro from "./pages/intro/intro";
import About from "./pages/about/about";
import Services from "./pages/services/services";
import FAQ from "./pages/FAQ/FAQ";
import Contact from "./pages/contact/contact";
import Footer from "./pages/footer/footer";
// import Pricing from "./pages/pricing/pricing";
// import Blog from "./pages/blog/blog";
import AboutUs from "./pages/aboutUs/aboutUs";
import Partner from "./pages/partner/partner";
import Careers from "./pages/careers/careers";
import { Route, Routes } from "react-router-dom";
import Home from "./home/home";
import Events from "./pages/events/events";
import NotFound from "./pages/404";
import TermsAndConditions from "./pages/terms";
// import News from "./pages/news/news";

function App() {
  return (
    <div>
      <Routes>
        <Route path="/" element={<Home />} />
        <Route path="/about" element={<AboutUs />} />
        {/* <Route path="/blog" element={<Blog />} /> */}
        <Route path="/partner" element={<Partner />} />
        <Route path="/terms-and-conditions" element={<TermsAndConditions />} />
        
        <Route path="*" element={<NotFound />} />
        {/* <Route path="/pricing" element={<Pricing />} /> */}
        {/* <Route path="/events" element={<Events />} /> */}
        {/* <Route path="/careers" element={<Careers />} /> */}
        {/* <Route path="/news" element={<News />} /> */}
      </Routes>
    </div>
  );
}

export default App;

/*
 */
