import Intro from "pages/intro/intro";
import About from "pages/about/about";
import Services from "pages/services/services";

import FAQ from "pages/FAQ/FAQ";
import Contact from "pages/contact/contact";
import Footer from "pages/footer/footer";

const Home = () => {
  return (
    <div>
      <Intro />
      <About />
      <Services />
      <FAQ />
      <Contact />
      <Footer />
    </div>
  );
};

export default Home;
