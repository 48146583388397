import Navbar from "../../components/navbar/navbar";
import { SlMouse } from "react-icons/sl";

const Intro = () => {
  return (
    <div id="home" className="w-full h-auto">
      <Navbar />
      <section className="pt-48 w-full flex justify-center">
        <div>
          <h1 className="w-full h-auto text-[48px] text-center mb-10">
            MyPractice Medical AI Scribe,
            <br /> a revolutionary AI-powered ambient
            <br />
            listening technology for
            <br /> clinical documentation
          </h1>
          <div className="w-full flex justify-center mb-5">
            <img src="/wav_pic.gif" />
          </div>
          <div className="w-full flex justify-center mb-8">
            <a
              href="#contact"
              className="w-[240px] h-[50px] bg-teal-500 text-white rounded-full text-[24px] flex justify-center items-center"
            >
              Request a Demo
            </a>
          </div>
          <div className="w-full flex justify-center mb-24">
            <SlMouse className=" rotate-180 text-4xl" />
          </div>
        </div>
      </section>
    </div>
  );
};

export default Intro;
