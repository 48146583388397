import React, { useState } from "react";
import { IoMenu } from "react-icons/io5";
import { IoMdClose } from "react-icons/io";
import { Link, useNavigate } from "react-router-dom";
import { FaCaretDown } from "react-icons/fa6";

const nav = [
  {
    id: 1,
    tit: "Home",
    child: null,
    link: "/#home",
  },
  {
    id: 2,
    tit: "How it works",
    child: null,
    link: "/#service",
  },
  {
    id: 3,
    tit: "FAQs",
    child: null,
    link: "/#faq",
  },
  {
    id: 4,
    tit: "Resources",
    link: null,
    child: [
      {
        tits: "Events",
        link: "/events",
      },
      {
        tits: "IN THE News",
        link: "/news",
      },
    ],
  },

  {
    id: 6,
    tit: "About",
    link: null,
    child: [
      {
        tits: "About Us",
      },
      {
        tits: "Partner with MyPractice",
      },
      {
        tits: "Careers",
      },
    ],
  },
];

const Navbar = () => {
  const [opens, seOpen] = useState(false);
  const [ab, setAbt] = useState(false);
  const navigate = useNavigate()

  const handle = () => {
    seOpen(!opens);
  };

  const handleAb = () => {
    setAbt(!ab);
  };


  const goToSales = () => {
    handle()
    navigate('/#contact')
  }

  return (
    <div className="w-full h-[100px] bg-white fixed top-0 left-0 z-40">
      <div className="w-full h-[100px] bg-white relative">
        <div
          className={`fixed w-full flex justify-between h-screen bg-gray-200 top-0 right-0 ${opens ? "visited:" : "hidden"
            }`}
        >
          <section>
            <ul className="p-10">
              <a href="#aboutme" className="text-2xl mb-5">
                Home
              </a>
              <li className="text-2xl mb-5">
                <a href="/#service">
                  How it works
                </a>
              </li>
              <li className="text-2xl mb-5">
                <a href="/#faq">
                  FAQs
                </a>
              </li>
              <li className="text-2xl mb-5">
                <a href="/about">
                  About
                </a>
              </li>
              <li>
                <button onClick={goToSales} className="w-[180px] h-[45px] bg-teal-500 text-white rounded-full text-[22px]">
                  Talk to Sales
                </button>
              </li>
            </ul>
          </section>
          <section className="p-10">
            <IoMdClose
              className="text-3xl hover:opacity-60 cursor-pointer"
              onClick={handle}
            />
          </section>
        </div>
        <div className="lg:visible hidden pl-20 pr-20 w-full h-full lg:flex items-center justify-between">
          <section>
            <div className="w-[150px] h-[80px]">
              <img
                draggable={false}
                src="/robot.png"
                className="w-full h-full object-contain"
              />
            </div>
          </section>
          <section className="">
            <ul className="flex items-center gap-5 ">
              <a
                href="/#home"
                className="hover:text-teal-500 duration-300"
              >
                Home
              </a>
              <a
                href="/#service"
                className="hover:text-teal-500 duration-300"
              >
                How it works
              </a>
              <a
                href="/#faq"
                className="hover:text-teal-500 duration-300"
              >
                FAQs
              </a>

              <div className="relative">
                <button
                  onClick={handleAb}
                  className="flex items-center gap-2 hover:text-teal-500 duration-300"
                >
                  <h1>About</h1> <FaCaretDown className="text-teal-500" />
                </button>
                <div
                  className={`w-[200px] h-auto bg-gray-50 shadow-md shadow-gray-500 rounded-2xl flex justify-center items-center flex-col p-5 absolute gap-5 ${ab ? "visible" : " hidden"
                    }`}
                >
                  <div className="w-[120px] h-[50px] rounded-full hover:bg-teal-200 flex justify-center items-center">
                    <a
                      href={"/about"}
                      className="font-bold "
                    >
                      About Us
                    </a>
                  </div>
                  <div className="w-auto h-[50px] rounded-full hover:bg-teal-200 flex justify-center items-center p-1">
                    <a
                      href={"/partner"}
                      className="font-bold text-center"
                    >
                      Partner with MyPractice
                    </a>
                  </div>
                </div>
              </div>

              <li>
                <a
                  href="/#contact"
                  className="flex items-center justify-center w-[180px] h-[45px] bg-teal-500 text-white rounded-full text-[22px]"
                >
                  Talk to Sales
                </a>
              </li>
            </ul>
          </section>
        </div>
        <div className="visible lg:hidden pl-5 pr-5 pt-5 w-full h-full flex items-center justify-between">
          <section>
            <div className="w-[150px] h-[80px]">
              <img
                src="/robot.png"
                className="w-full h-full object-contain"
              />
            </div>
          </section>
          <section>
            <IoMenu
              className="text-3xl hover:opacity-60 cursor-pointer"
              onClick={handle}
            />
          </section>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
