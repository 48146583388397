export const FaqDatas = [
  {
    id: 299,
    title: "How do I get started with using MyPractice?",
    desc: "To start using MyPractice medical ai scribe, simply click the “Connect Now” button to request a demo. Our team can guide you through the setup and implementation process, ensuring a seamless transition to our AI-powered ambient experience technology.",
    show: true,
  },
  {
    id: 301,
    title: "How accurate is the clinical documentation generated by MyPractice?",
    desc: "The clinical documentation generated by MyPractice is highly accurate, thanks to its advanced natural language processing and machine learning algorithms.",
    show: false,
  },
  {
    id: 303,
    title: "Can MyPractice recognize different accents and dialects?",
    desc: "Yes, MyPractice can recognize different accents and dialects, making it accessible to a wide range of healthcare providers and patients.",
    show: false,
  },
  {
    id: 305,
    title: "Is MyPractice HIPAA Compliant?",
    desc: "MyPractice takes privacy and security of patient data very seriously. We sign business associate agreement as required under HIPAA. MyPractice follows the necessary Administrative, Physical & Technical safeguards as required by HIPAA. MyPractice uses industry-standard encryption and security protocols. It’s important to note, no hardware or software by itself is HIPAA Compliant. The users using the product need to follow their obligations to confirm its compliance.",
    show: false,
  },
  {
    id: 307,
    title: "Can MyPractice Medical AI Scribe be customized to fit the specific needs of my practice?",
    desc: "Yes, such as by adding custom templates or fields to the documentation process.",
    show: false,
  },
  {
    id: 311,
    title: "How does MyPractice Medical AI Scribe handle complex medical terminology or unusual medical cases?",
    desc: "MyPractice can handle complex medical terminology or unusual medical cases thanks to its advanced algorithms and the ability to learn from new data and feedback.",
    show: false,
  },
  {
    id: 313,
    title: "How does MyPractice improve the efficiency and quality of patient care?",
    desc: "MyPractice improves the efficiency and quality of patient care by streamlining the documentation process and reducing errors, allowing doctors to spend less time on admin and more time with patients.",
    show: false,
  },
  {
    id: 315,
    title: "How does MyPractice handle documentation requirements?",
    desc: "MyPractice handles documentation requirements by providing tools to capture and record a patient’s conversation with the provider and ensuring that all relevant documentation is captured and recorded accurately.",
    show: false,
  },
  {
    id: 317,
    title: "Is MyPractice Medical AI Scribe for multiple specialties, or is it for a specific medical field?",
    desc: "MyPractice Medical AI Scribe can be for multiple specialties, including primary care, specialty care and more.",
    show: false,
  },
  {
    id: 319,
    title: "How does MyPractice handle voice recognition and transcription errors?",
    desc: "MyPractice handles voice recognition and transcription errors by using advanced algorithms to learn from errors and improve accuracy over time.",
    show: false,
  },
  {
    id: 321,
    title: "Is MyPractice Medical AI Scribe for mobile devices also, or is it only accessible on desktop computers?",
    desc: "MyPractice Medical AI Scribe is for desktop computers, iOS and Android through an app.",
    show: false,
  },
  {
    id: 327,
    title: "Can MyPractice help me identify potential risks or issues with patient care based on the documentation captured during visits?",
    desc: "Yes, MyPractice can help you identify potential risks or issues with patient care based on the documentation captured during visits, such as by flagging potential drug interactions or other concerns.",
    show: false,
  },
  {
    id: 329,
    title: "Are there any tips for optimal use of MyPractice?",
    desc: "To ensure the best results when using MyPractice, we recommend that you speak clearly and enunciate, position yourself near the microphone, and minimize background noise. This can help with accurate transcription and ensure that relevant information is captured.",
    show: false,
  },
];
