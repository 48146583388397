import React from "react";

const About = () => {
  return (
    <div
      className="w-full h-auto bg-[#F4F4F4] bg-opacity-65 relative"
      id="aboutme"
    >

      <div className="w-full lg:flex pl-24 pr-24 pt-28 pb-2">
        <section className="lg:flex-1">
          <h1 className="text-[46px] font-[500] mb-8">
            Your Medical AI Scribe
          </h1>
          <div className="flex gap-5">
            <div className="w-[4px] h-auto bg-teal-500"></div>
            <p className="lg:text-[24px] text-[16px] lg:w-[95%] mb-5">
              MyPractice is a medical AI scribe which translates the natural
              conversations between healthcare providers and patients into
              clinical documentation. MyPractice offers a unique and immersive
              experience for both doctors and patients, making the documentation
              of clinical notes faster and more efficient than ever before. Use
              it with your EHR to accelerate your documentation.
            </p>
          </div>
        </section>
      </div>
    </div>
  );
};

export default About;
