import { useState } from "react";
import TermsModal from "./partials/terms-modal";


const Footer = () => {
  const year = new Date().getFullYear()
  const [currentModal, setCurrentModal] = useState<string | null>()

  type LinkType = {
    tab: string,
    title: string
  }

  const links: LinkType[] = [
    {
      tab: "privacy_policy",
      title: "Your Privacy Choices"
    },
    {
      tab: "terms_of_use",
      title: "Terms of usage"
    },
    {
      tab: "acceptable_use_policy",
      title: "Acceptable Use Policy"
    },
  ]

  const showLinkModal = (tab: string) => {
    setCurrentModal(tab)
  }

  return (
    <>
      {
        currentModal && <TermsModal tab={currentModal} onClose={() => setCurrentModal(null)} />
      }
      <div className="w-full h-[150px] bg-black mt-28 flex lg:justify-end justify-center items-end flex-col">

        <div className="lg:flex items-center justify-center gap-5">
          <ul className="text-white flex justify-center items-center gap-8 p-5">
            {
              links.map((link, key) => <button key={key} className="text-sm" onClick={() => showLinkModal(link.tab)}>{link.title}</button>)
            }
          </ul>
        </div>
        <div className="lg:flex p-5 items-center justify-center gap-5">
          <p className="text-white">
            Copyright ©{year} MyPractice
          </p>
        </div>
      </div>
    </>
  );
};

export default Footer;
