
const Services = () => {
  return (
    <div
      id="service"
      className="w-full h-auto flex flex-col justify-center bg-white pt-24"
    >
      <h1 className="flex justify-center text-5xl font-[500] mb-24 text-center leading-normal">
        How Medical AI Scribe Works
      </h1>

      <div className="flex justify-between w-full lg:h-[200px] h-full">
        <section className="w-full h-full flex justify-end p-3 items-center pr-10">
          <img src="/microphone.png" />
        </section>
        <section className="w-[2px] h-auto border-[1px] border-teal-500"></section>
        <section className="w-full h-full flex justify-start p-3 pl-10">
          <section>
            <div className="w-[50px] h-[50px] rounded-full border-[1px] border-gray-300 mb-3 flex justify-center items-center">
              1
            </div>
            <h1 className="font-bold text-lg">Listens to the conversation</h1>
            <p className=" max-w-[500px]">
              Similar to a medical scribe, MyPractice listens to patient-provider
              conversations to help document clinical information accurately and
              efficiently.
            </p>
          </section>
        </section>
      </div>

      <div className="flex justify-between w-full lg:h-[200px] h-auto">
        <section className="w-full h-full flex justify-end p-3 pr-10">
          <section>
            <div className="flex justify-end">
              <div className="w-[50px] h-[50px] rounded-full border-[1px] border-gray-300 mb-3 flex justify-center items-center">
                2
              </div>
            </div>

            <h1 className="font-bold text-lg flex justify-end text-end">
              Generates a dialogue flow
            </h1>
            <p className=" max-w-[500px] text-end">
              MyPractice uses AI and voice recognition technology to create a transcript of the dialogue flow between providers and patients.
            </p>
          </section>
        </section>
        <section className="w-[2px] h-auto  border-[1px] border-teal-500"></section>
        <section className="w-full h-full flex justify-start p-3 items-center pl-10">
          <img src="/group-92.png" />
        </section>
      </div>

      <div className="flex justify-between w-full lg:h-[200px] h-auto">
        <section className="w-full h-full flex justify-end p-3 items-center pr-10">
          <img src="/group-87.png" />
        </section>
        <section className="w-[2px] h-auto  border-[1px] border-teal-500"></section>
        <section className="w-full h-full flex justify-start p-3 pl-10">
          <section>
            <div className="w-[50px] h-[50px] rounded-full border-[1px] border-gray-300 mb-3 flex justify-center items-center">
              3
            </div>
            <h1 className="font-bold text-lg">Generates clinical documentation</h1>
            <p className=" max-w-[500px]">
              MyPractice medical ai scribe categorizes the summarized content into various progress note sections and allows for reviewing and importing relevant data for clinical documentation
            </p>
          </section>
        </section>
      </div>

      <div className="flex justify-between w-full lg:h-[200px] h-auto ">
        <section className="w-full h-full flex justify-end p-3 pr-10">
          <section>
            <div className="flex justify-end">
              <div className="w-[50px] h-[50px] rounded-full border-[1px] border-gray-300 mb-3 flex justify-center items-center">
                4
              </div>
            </div>

            <h1 className="font-bold text-lg flex justify-end text-end">
              Assists with order entry
            </h1>
            <p className=" max-w-[500px] text-end">
                MyPractice's summary captures lab, imaging, procedure, and medication orders, as well as follow-up visit details.
            </p>
          </section>
        </section>
        <section className="w-[2px] h-auto  border-[1px] border-teal-500"></section>
        <section className="w-full h-full flex justify-start p-3 items-center pl-10">
        <img src="/group-93.png" />
        </section>
      </div>

      <div className="flex justify-between w-full lg:h-[200px] h-auto">
        <section className="w-full h-full flex justify-end p-3 items-center pr-10">
          <img src="/group-89.png" />
        </section>
        <section className="w-[2px] h-auto  border-[1px] border-teal-500"></section>
        <section className="w-full h-full flex justify-start p-3 pl-10">
          <section>
            <div className="w-[50px] h-[50px] rounded-full border-[1px] border-gray-300 mb-3 flex justify-center items-center">
              5
            </div>
            <h1 className="font-bold text-lg">Review, modify and import</h1>
            <p className=" max-w-[500px]">
              Sunoh streamlines the clinical documentation process by allowing healthcare providers to review summarized content for accuracy and completeness, modify it if necessary, and merge pre-configured defaults with a single click.
            </p>
          </section>
        </section>
      </div>


    </div>
  );
};

export default Services;
