export const privacyPolicy = `Acceptable Use Policy
This Acceptable Use Policy ("Policy") sets forth the rules and guidelines for acceptable use of the My Practice website ("Site") and services offered by My Practice.
Prohibited Activities
You agree not to use the Site for any purpose that is illegal or prohibited by this Policy. You may not use the Site in any manner that could damage, disable, overburden, or impair the Site or interfere with any other party's use and enjoyment of the Site. You may not attempt to gain unauthorized access to any part of the Site, other accounts, computer systems, or networks connected to the Site, through hacking, password mining, or any other means. You may not use the Site to engage in any activity that infringes or violates the rights of others, including but not limited to intellectual property rights, privacy rights, or rights of publicity.
User Responsibilities
Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account. Users agree to notify My Practice immediately of any unauthorized use of their account or any other breach of security. Users are solely responsible for the content they post on the Site and agree not to post any content that is illegal, defamatory, obscene, abusive, or otherwise objectionable. Users agree to comply with all applicable laws and regulations regarding online conduct and content.
Consequences of Violation
Violation of this Policy may result in termination of your access to the Site and/or other actions deemed appropriate by My Practice, including but not limited to legal action. My Practice reserves the right to investigate and take legal action against anyone who violates this Policy, including but not limited to removing offending content, terminating accounts, and reporting violations to law enforcement authorities.
Changes to Policy
My Practice reserves the right to update or modify this Policy at any time without prior notice. Your continued use of the Site after any such changes constitutes your acceptance of the new Policy.

Prohibited Activities
You agree not to use the Site for any purpose that is illegal or prohibited by this Policy. Prohibited activities include, but are not limited to:
Posting, transmitting, or otherwise making available any content that is unlawful, harmful, threatening, abusive, harassing, defamatory, vulgar, obscene, libelous, invasive of another's privacy, hateful, or racially, ethnically, or otherwise objectionable.
Impersonating any person or entity, including but not limited to a My Practice official, forum leader, guide, or host, or falsely stating or otherwise misrepresenting your affiliation with a person or entity.
Uploading, posting, emailing, transmitting, or otherwise making available any content that you do not have a right to make available under any law or under contractual or fiduciary relationships (such as inside information, proprietary, and confidential information learned or disclosed as part of employment relationships or under nondisclosure agreements).
Uploading, posting, emailing, transmitting, or otherwise making available any content that infringes any patent, trademark, trade secret, copyright, or other proprietary rights ("Rights") of any party.
Uploading, posting, emailing, transmitting, or otherwise making available any material that contains software viruses or any other computer code, files, or programs designed to interrupt, destroy, or limit the functionality of any computer software or hardware or telecommunications equipment.
Interfering with or disrupting the Site or servers or networks connected to the Site, or disobeying any requirements, procedures, policies, or regulations of networks connected to the Site.
Collecting or storing personal data about other users without their express consent.
User Responsibilities
Users are responsible for maintaining the confidentiality of their account credentials and for all activities that occur under their account. Users agree to notify My Practice immediately of any unauthorized use of their account or any other breach of security. Users are solely responsible for the content they post on the Site and agree not to post any content that is illegal, defamatory, obscene, abusive, or otherwise objectionable. Users agree to comply with all applicable laws and regulations regarding online conduct and content.
Consequences of Violation
Violation of this Policy may result in termination of your access to the Site and/or other actions deemed appropriate by My Practice, including but not limited to legal action. My Practice reserves the right to investigate and take legal action against anyone who violates this Policy, including but not limited to removing offending content, terminating accounts, and reporting violations to law enforcement authorities.
Changes to Policy
My Practice reserves the right to update or modify this Policy at any time without prior notice. Your continued use of the Site after any such changes constitutes your acceptance of the new Policy.
`

export const termsOfUse = `Terms of Use
Welcome to My Practice! These terms and conditions outline the rules and regulations for the use of our website.
By accessing this website, we assume you accept these terms and conditions in full. Do not continue to use My Practice's website if you do not accept all of the terms and conditions stated on this page.
The following terminology applies to these Terms and Conditions, Privacy Statement and Disclaimer Notice and any or all Agreements: "Client," "You" and "Your" refers to you, the person accessing this website and accepting the Company's terms and conditions. "The Company," "Ourselves," "We," "Our" and "Us," refers to our Company. "Party," "Parties," or "Us," refers to both the Client and ourselves, or either the Client or ourselves. All terms refer to the offer, acceptance, and consideration of payment necessary to undertake the process of our assistance to the Client in the most appropriate manner, whether by formal meetings of a fixed duration or any other means, for the express purpose of meeting the Client's needs in respect of provision of the Company's stated services/products, in accordance with and subject to, prevailing law of the United States. Any use of the above terminology or other words in the singular, plural, capitalization and/or he/she or they, are taken as interchangeable and therefore as referring to the same.
Cookies We employ the use of cookies. By using My Practice's website you consent to the use of cookies in accordance with My Practice's privacy policy.
Most of the modern-day interactive websites use cookies to enable us to retrieve user details for each visit. Cookies are used in some areas of our site to enable the functionality of this area and ease of use for those people visiting.
License Unless otherwise stated, My Practice and/or its licensors own the intellectual property rights for all material on My Practice. All intellectual property rights are reserved. You may view and/or print pages from https://mypractice.com for your own personal use subject to restrictions set in these terms and conditions.
You must not:
Republish material from https://mypractice.com
Sell, rent or sub-license material from https://mypractice.com
Reproduce, duplicate or copy material from https://mypractice.com
Redistribute content from My Practice (unless the content is specifically made for redistribution).
Reservation of Rights We reserve the right at any time and in its sole discretion to request that you remove all links or any particular link to our Website. You agree to immediately remove all links to our Website upon such request. We also reserve the right to amend these terms and conditions and its linking policy at any time. By continuing to link to our Website, you agree to be bound to and abide by these linking terms and conditions.
Removal of links from our website If you find any link on our Website that is offensive for any reason, you are free to contact and inform us at any moment. We will consider requests to remove links, but we are not obligated to or so or to respond to you directly.
Content Liability We shall not be held responsible for any content that appears on your Website. You agree to protect and defend us against all claims that are rising on your Website. No link(s) should appear on any Website that may be interpreted as libelous, obscene or criminal, or which infringes, otherwise violates, or advocates the infringement or other violation of, any third party rights.
Disclaimer To the maximum extent permitted by applicable law, we exclude all representations, warranties, and conditions relating to our website and the use of this website (including, without limitation, any warranties implied by law in respect of satisfactory quality, fitness for purpose and/or the use of reasonable care and skill). Nothing in this disclaimer will:
Limit or exclude our or your liability for death or personal injury resulting from negligence.
Limit or exclude our or your liability for fraud or fraudulent misrepresentation.
Limit any of our or your liabilities in any way that is not permitted under applicable law.
Exclude any of our or your liabilities that may not be excluded under applicable law.
The limitations and exclusions of liability set out in this Section and elsewhere in this disclaimer: (a) are subject to the preceding paragraph; and (b) govern all liabilities arising under the disclaimer or in relation to the subject matter of this disclaimer, including liabilities arising in contract, in tort (including negligence) and for breach of statutory duty.

User Account
To access certain features of My Practice, you may be required to create a user account. You agree to provide accurate and complete information when creating your account and to update your information promptly if it changes.
You are responsible for maintaining the confidentiality of your account credentials and for all activities that occur under your account. You agree to notify us immediately of any unauthorized use of your account or any other breach of security.
User Content
My Practice allows users to create and share content such as text, images, and videos. By submitting content to My Practice, you grant us a non-exclusive, royalty-free, worldwide, perpetual, and irrevocable license to use, reproduce, modify, adapt, publish, translate, distribute, and display such content.
You agree not to submit any content that is illegal, defamatory, obscene, abusive, or otherwise objectionable. We reserve the right to remove any content that violates these terms or is otherwise deemed inappropriate.
Limitation of Liability
My Practice and its affiliates, officers, directors, employees, agents, and licensors shall not be liable for any direct, indirect, incidental, special, consequential, or punitive damages, including but not limited to, damages for loss of profits, goodwill, use, data, or other intangible losses resulting from:
the use or inability to use My Practice;
unauthorized access to or use of your personal information;
any bugs, viruses, trojan horses, or the like that may be transmitted to or through My Practice by any third party;
any errors or omissions in any content or for any loss or damage incurred as a result of the use of any content posted, emailed, transmitted, or otherwise made available through My Practice.
Indemnification
You agree to indemnify, defend, and hold harmless My Practice and its affiliates, officers, directors, employees, agents, and licensors from and against any and all claims, liabilities, damages, losses, costs, expenses, or fees (including reasonable attorneys' fees) arising from:
your use of and access to My Practice;
your violation of any term of these Terms of Use;
your violation of any third party right, including without limitation any copyright, property, or privacy right; or
any claim that your content caused damage to a third party.
Termination
We reserve the right to terminate or suspend your account and access to My Practice at any time and for any reason, without notice. Upon termination, your right to use My Practice will immediately cease.
Governing Law
These Terms of Use shall be governed by and construed in accordance with the laws of [Your Jurisdiction], without regard to its conflict of law principles.
Changes to Terms
We reserve the right to update or modify these Terms of Use at any time without prior notice. Your continued use of My Practice after any such changes constitutes your acceptance of the new Terms of Use.

`

export const acceptableUsePolicy = `Privacy Policy for My Practice Website
Protecting your privacy is important to us. This Privacy Policy explains how My Practice ("we," "us," or "our") collects, uses, and discloses information from and about users of our website (the "Website").
Information We Collect:
Account Information: When you create an account, we collect information such as your name, email address, phone number, and practice information.
Profile Information: You may choose to provide additional information to your profile, such as your qualifications, experience, and areas of expertise.
Usage Data: We collect information about how you use the Website, such as the pages you visit, the features you use, and the content you access.
How We Use Your Information:
Provide and improve the Website: We use your information to operate and maintain the Website, provide you with the services you request, and personalize your experience.
Communicate with you: We may use your information to send you important information about the Website, such as updates, security alerts, and support messages.
Marketing: We may use your information to send you marketing communications about our services, but we will provide you with an option to opt out of receiving such communications.
Disclosure of Your Information:
Service Providers: We may share your information with third-party service providers who help us operate and maintain the Website. These service providers are obligated to protect your information and use it only for the purposes we have disclosed.
Legal Requirements: We may disclose your information if we are required to do so by law or in the good faith belief that such disclosure is necessary to comply with legal process, protect the safety of others, or investigate fraud.
Your Choices:
Access and Update Your Information: You can access and update your information in your account settings.
Opt Out of Marketing Communications: You can opt out of receiving marketing communications from us by following the unsubscribe instructions in those communications.
Data Security:
We take reasonable steps to protect your information from unauthorized access, disclosure, alteration, or destruction. However, no website or internet transmission is completely secure.
Children's Privacy:
The Website is not intended for children under the age of 13. We do not knowingly collect personal information from children under 13.
Changes to this Privacy Policy:
We may update this Privacy Policy from time to time. We will post any changes on the Website and notify you by email if the changes are material.
Contact Us:
If you have any questions about this Privacy Policy, please contact us at [your email address].
Disclaimer:
This is a sample privacy policy and may not be suitable for all websites. You should consult with an attorney to ensure that your privacy policy complies with all applicable laws and regulations.

Data Retention:
Specify how long you will retain user data and the criteria for deletion.
Cookies and Tracking Technologies:
Explain how you use cookies and other tracking technologies on the Website and how users can control their settings.
International Transfers:
If you transfer user data to other countries, explain the safeguards in place to protect data privacy.
Third-Party Links:
Disclose your practices regarding links to third-party websites and how user data may be handled on those sites.
Security Breach Notification:
Outline your procedures for notifying users in case of a data breach.
Dispute Resolution:
Describe how users can resolve any disputes regarding their privacy.
Additional Information for Doctors:
Since your website caters to doctors, you may want to address specific privacy concerns related to patient data.
Clarify how My Practice handles any patient information that doctors might share through their profiles or practice management tools.
Emphasize your commitment to complying with HIPAA regulations and other relevant healthcare privacy laws.`