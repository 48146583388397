import { Box, Button, Grid } from '@mui/material';
import { FC } from 'react';
import DialogModal from './dialog-modal';

interface TermsModalProps {
    tab: string
    onClose: () => void
}

const TermsModal: FC<TermsModalProps> = ({ tab, onClose }) => {

    const fullUrl = "https://dev.mypractice.clinic/terms/" + tab + "?iframe=true"

    return <DialogModal
    open={true}
    fullWidth
    onClose={onClose}
    maxWidth={'lg'}
    title={"Medical Notes"}
    children={
        <Box sx={{
            height: '100vh',
            width: '100%',
            position: 'relative',
            overflow: 'hidden'
        }}>
            <iframe title="External Site" src={fullUrl} width="100%" height="100%"
                frameBorder="0"
                loading='lazy'
            />
        </Box>
    }
    actions={<>
        <Grid container justifyContent={'space-between'} p={2} alignItems={'center'}>
            <Button
                variant="outlined"
                size="small"
                sx={{
                    color: 'primary.main',
                    fontWeight: 400,
                    p: 0.5,
                    '&:hover': { border: 'none' }
                }}
                onClick={onClose}>
                Close
            </Button>
        </Grid>
    </>}
/>
}


export default TermsModal
