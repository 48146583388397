import axios from "axios";
import { useForm, SubmitHandler } from "react-hook-form";
import * as Yup from "yup";
import { yupResolver } from "@hookform/resolvers/yup";

const Contact = () => {
  let validations: any = {
    first_name: Yup.string().required("First Name is required").min(2),
    last_name: Yup.string().required("Last Name is required").min(2),
    email: Yup.string()
      .required("Email is required")
      .matches(
        /^[a-zA-Z0-9._-]+@[a-zA-Z0-9.-]+\.[a-zA-Z]{2,4}$/,
        "The email should be like this 'test@example.com'"
      ),
    phone: Yup.number().required("Phone is required").min(5).typeError("Valid phone is required"),
    organization: Yup.string().required("Organization is required").min(3),
    ehr: Yup.string().required("Ehr is required").min(2),
    providers: Yup.string().required("Providers is required").min(1),
    zip_code: Yup.number().required("Zip code is required").min(3).typeError("Zip code is required"),
  };

  const validation = (validations: any) => {
    if (validations) return Yup.object().shape(validations);
    else return Yup.object().shape({});
  };

  const {
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm({ resolver: yupResolver(validation(validations) as any) });

  const onSubmit = (data: any) => {
    axios
      .post("/api/contact/", {
        ...data,
      })
      .then((res) => {
        alert("Message received.")
        window.location.reload()
      })
      .catch((err) => console.log(err));
  };

  return (
    <div id="contact" className="w-full h-auto">
      <section className="w-full h-full pl-24 pr-24 flex justify-center">
        <div className="pt-32">
          <section className="flex items-center flex-col">
            <form
              onSubmit={handleSubmit(onSubmit)}
              className="flex items-center flex-col"
            >
              <h1 className="lg:text-[54px] text-[34px] font-[700]">
                Interested in MyPractice Medical AI Scribe?
              </h1>
              <h2 className="lg:text-[54px] text-[32px] font-[400] mb-10">
                Start your conversation
              </h2>
              <div className="lg:w-[750px]">
                <section className="lg:flex items-center w-full gap-5 mb-10">
                  <div className="w-full">
                    <h1 className="mb-3 font-[600]">First Name*</h1>
                    <div className="relative">
                      <input
                        {...register("first_name")}
                        className="w-full h-[50px] rounded-xl border-[1px] border-black"
                      />
                      {errors.first_name && (
                        <p className="text-xs text-red-500 absolute bottom-[-20px]">
                          {errors.first_name.message as any}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <h1 className="mb-3 font-[600]">Last Name*</h1>
                    <div className="relative">
                      <input
                        {...register("last_name")}
                        className="w-full h-[50px] rounded-xl border-[1px] border-black"
                      />
                      {errors.last_name && (
                        <p className="text-xs text-red-500 absolute bottom-[-20px]">
                          {errors.last_name.message as any}
                        </p>
                      )}
                    </div>
                  </div>
                </section>

                <section className="lg:flex items-center w-full gap-5 mb-10">
                  <div className="w-full">
                    <h1 className="mb-3 font-[600]">Work Email*</h1>
                    <div className="relative">
                      <input
                        {...register("email")}
                        className="w-full h-[50px] rounded-xl border-[1px] border-black"
                      />
                      {errors.email && (
                        <p className="text-xs text-red-500 absolute bottom-[-20px]">
                          {errors.email.message as any}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <h1 className="mb-3 font-[600]">Phone Number*</h1>
                    <div className="relative">
                      <input
                        {...register("phone")}
                        className="w-full h-[50px] rounded-xl border-[1px] border-black"
                      />
                      {errors.phone && (
                        <p className="text-xs text-red-500 absolute bottom-[-20px]">
                          {errors.phone.message as any}
                        </p>
                      )}
                    </div>
                  </div>
                </section>

                <div className="lg:flex items-center gap-3 mb-5">
                  <input type="checkbox" {...register("prefer-text")} />
                  <h1 className="font-[500]">
                    Do You Prefer to Communicate via Text?
                  </h1>
                </div>
                <div>
                  <p className="font-[400] mb-10">
                    By selecting this option/the text message option, I am
                    consenting to receiving communications from eClinicalWorks
                    using this method. Further, I understand that by opting to
                    receive text messages, standard message and data rates may
                    apply.
                  </p>
                </div>

                <section className="lg:flex items-center w-full gap-5 mb-10">
                  <div className="w-full">
                    <h1 className="mb-3 font-[600]">
                      Name of Your Organization*
                    </h1>
                    <div className="relative">
                      <input
                        {...register("organization")}
                        className="w-full h-[50px] rounded-xl border-[1px] border-black"
                      />
                      {errors.organization && (
                        <p className="text-xs text-red-500 absolute bottom-[-20px]">
                          {errors.organization.message as any}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <h1 className="mb-3 font-[600]">
                      What EHR are You Currently Using?*
                    </h1>
                    <div className="relative">
                      <input
                        {...register("ehr")}
                        className="w-full h-[50px] rounded-xl border-[1px] border-black"
                      />
                      {errors.ehr && (
                        <p className="text-xs text-red-500 absolute bottom-[-20px]">
                          {errors.ehr.message as any}
                        </p>
                      )}
                    </div>
                  </div>
                </section>

                <section className="lg:flex items-center w-full gap-5 mb-10">
                  <div className="w-full">
                    <h1 className="mb-3 font-[600]">Number of Providers*</h1>
                    <div className="relative">
                      <input
                        {...register("providers")}
                        className="w-full h-[50px] rounded-xl border-[1px] border-black"
                      />
                      {errors.providers && (
                        <p className="text-xs text-red-500 absolute bottom-[-20px]">
                          {errors.providers.message as any}
                        </p>
                      )}
                    </div>
                  </div>

                  <div className="w-full">
                    <h1 className="mb-3 font-[600]">ZIP Code*</h1>
                    <div className="relative">
                      <input
                        {...register("zip_code")}
                        className="w-full h-[50px] rounded-xl border-[1px] border-black"
                      />
                      {errors.zip_code && (
                        <p className="text-xs text-red-500 absolute bottom-[-40px]">
                          {errors.zip_code.message as any}
                        </p>
                      )}
                    </div>
                  </div>
                </section>

                <p className="mb-10">
                  Your privacy is important to us Privacy Policy.
                </p>
              </div>
              <button
                type="submit"
                className="w-[120px] h-[50px] rounded-lg bg-teal-600 font-[600] text-white"
              >
                SUBMIT
              </button>
            </form>
          </section>
        </div>
      </section>
    </div>
  );
};

export default Contact;
