import React from "react";
import Navbar from "../../components/navbar/navbar";
import "./abt.css";
import Footer from "../footer/footer";

const AboutUs = () => {
  return (
    <div>
      <Navbar />
      <div className="w-full lg:h-auto mb-10 cl flex flex-col items-center">
        <section className="w-[70%] h-auto pt-48">
          <h1 className="lg:text-[48px] text-[38px] font-[500]  mb-5 text-center">
            The road to getting more time back starts with MyPractice.clinic
          </h1>

          <p className="mb-24 lg:text-[24px] text-[16px] flex gap-3">
            <div className="h-auto w-[6px] bg-teal-500"></div>
            At MyPractice.clinic we are passionate about improving healthcare delivery
            through AI and ambient listening technology. We are engineers,
            medical professionals, and dreamers who want to help physicians
            reduce administrative burdens and get more time back in their day.
            <br />
            Doctors, nurse practitioners, and medical professionals worldwide
            are dedicated to providing quality healthcare. We are committed to
            helping these professionals by providing a service that makes their
            lives easier, saves time, reduces burnout, and improves care.
          </p>
        </section>
      </div>
      <div className="w-full lg:h-auto  bg-gray-100 flex flex-col items-center">
        <section className="w-[70%]">
          <h1 className="text-[48px] font-[500] mt-24 mb-5 text-center">
            MyPractice: Listen
          </h1>
          <p className="mb-24 lg:text-[24px] text-[16px] flex gap-3">
            <div className="h-auto w-[6px] bg-teal-500"></div>
            MyPractice means “listen,” and that’s just what we did. For years, we
            have heard the stories and experienced burnout firsthand. By
            listening to physicians and other medical professionals, we realized
            that our goal was simple: eliminate the need for providers to split
            their focus between listening to the patient and taking clinical
            notes. To enhance a provider’s ability to do that, we harnessed the
            power of Artificial Intelligence to develop a virtual scribe for
            clinical documentation. Through this solution, we’ve improved the
            efficiency of this process by allowing the providers to focus
            entirely on patient interactions and not documentation.
          </p>
        </section>
      </div>
      <div className="w-full lg:h-[500px]  flex flex-col items-center">
        <section className="w-[70%]">
          <h1 className="text-[48px] font-[500] mt-24 mb-5 text-center">
            Why MyPractice?
          </h1>
          <p className="mb-5 lg:text-[24px] text-[16px] flex gap-3">
            <div className="h-auto w-[6px] bg-teal-500"></div>
            At MyPractice.clinic we are passionate about improving healthcare delivery
            through AI and ambient listening technology. We are engineers,
            medical professionals, and dreamers who want to help physicians
            reduce administrative burdens and get more time back in their day.
          </p>
          <p className="mb-5 lg:text-[24px] text-[16px] flex gap-3">
            <div className="h-auto w-[6px] bg-teal-500"></div>
            Doctors, nurse practitioners, and medical professionals worldwide
            are dedicated to providing quality healthcare. We are committed to
            helping these professionals by providing a service that makes their
            lives easier, saves time, reduces burnout, and improves care.
          </p>
        </section>
        <Footer />
      </div>
    </div>
  );
};

export default AboutUs;
