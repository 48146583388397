import { Link, useLocation, useSearchParams } from "react-router-dom"
import { acceptableUsePolicy, privacyPolicy, termsOfUse } from "./text"
import { useEffect } from "react"

const TermsAndConditions = () => {

    const tabs: Array<{
        title: string,
        content: string,
        slug: string
    }> = [
            {
                title: "Terms of Use",
                slug: "termsOfUse",
                content: termsOfUse
            },
            {
                title: "Privacy Policy",
                slug: "privacyPolicy",
                content: privacyPolicy
            },
            {
                title: "Acceptable use policy",
                slug: "acceptableUsePolicy",
                content: acceptableUsePolicy
            },
        ]

    useEffect(() => {
        window.scrollTo({
            top : 0,
            left: 0
        });
    }, [])

    const location = useLocation();
    const params = new URLSearchParams(location.search)
    const requestTab = params.get("tab")
    const activeTabSlug = tabs.filter((tab) => tab.slug === requestTab)[0]?.slug || "termsOfUse"
    const activeTab = tabs.filter((tab) => tab.slug === activeTabSlug)[0]

    return <div className="flex flex-col p-4">
        <div className="flex items-center justify-around p-2">
            {
                tabs.map((tab, key) => {
                    const isActive = tab.slug === activeTabSlug
                    return <div key={key} className={isActive ? "text-[#1a7979] p-2 rounded-md font-medium" : ""}>
                        <Link to={`/terms-and-conditions?tab=${tab.slug}`}>{tab.title}</Link>
                    </div>
                })
            }
        </div>
        <div className="p-2">
            <pre className="whitespace-pre-wrap">
                {activeTab.content}
            </pre>
        </div>
    </div>
}

export default TermsAndConditions